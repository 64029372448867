import { useEffect, useState } from "react";
import { modalStore } from "../store/modalsStore";
import { useClickAway } from "@uidotdev/usehooks";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '100%'
}
const SlideNextButton = ({children}) => {
    const swiper = useSwiper();
  
    return (
      <button className="text-red-500" onClick={() => swiper.slideNext()}>{children}</button>
    );
}
export default ({ loccation }) => {
    const [visible, setVisible] = useState(false);
    const { dataModal, dataId, currentTab, toggleModal, changeTab } = modalStore();
    const ref = useClickAway(() => {
        toggleModal(false);
    });
    useEffect(()=>{
        setVisible(true);
        return () => {
            setVisible(false);
        }
    }, []);

    return <>
        <div className={`fixed top-0 left-0 right-0 w-screen h-screen bg-[rgba(0,0,0,0.8)] z-50 overflow-hidden transition-all duration-300 ease-out
            ${visible ? '!opacity-100': 'opacity-0'}`}>
            <div className="w-full sm:h-full h-screen flex items-center justify-center">
                <div ref={ref} className="w-full sm:w-3/4 md:w-2/4 lg:w-1/3 h-full sm:h-auto shadow-none sm:shadow-[0px_0px_15px_rgba(255,255,255,.4)] rounded-none sm:rounded-lg overflow-hidden">
                    <div className={`h-[70%] sm:h-[35em] bg-cover bg-center bg-no-repeat relative overflow-hidden bg-black after:bg-[#9B8665] after:w-[60%] after:h-2 after:absolute after:-bottom-2 after:mx-auto after:translate-x-[35%]`}  style={currentTab=='video'?{background:'#000'}:{ }}>
                    {
                        currentTab=='video' ? <iframe className="h-full mx-auto aspect-auto" src={dataModal.videoUrl} allow="accelerometer; autoplay; muted; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> : <>
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {dataModal && dataModal.galleryImages.map((image, index) => (<SwiperSlide key={index} className="w-full h-full">
                                    <img src={image} className="w-full" />
                                </SwiperSlide>))}
                                <SlideNextButton>Next</SlideNextButton>
                            </Swiper>
                        </>
                    }
                    </div>
                    <div className="h-[30%] sm:h-44 bg-black p-4 px-6 flex flex-col gap-2 justify-around">
                        <div className="bg-white rounded-xl flex items-center gap-2 p-2">
                            <div className="bg-cover bg-no-repeat bg-center w-20 h-20 overflow-hidden rounded-md" style={{
                                backgroundImage: `url(${dataModal?.urlCardImage})`
                            }}>
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="flex gap-3">
                                    {dataModal?.socials?.facebook && <a href={dataModal.socials.facebook} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                            <svg className="w-5 h-5 text-white pl-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fillRule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clipRule="evenodd" />
                                            </svg>
                                        </a>}
                                    {dataModal?.socials?.instagram && <a href={dataModal.socials.instagram} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                        <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path fill="currentColor" fillRule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clipRule="evenodd" />
                                        </svg>
                                    </a>}
                                    {dataModal?.socials?.tiktok && <a href={dataModal.socials.tiktok} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                        <svg className='w-4 h-4 text-white' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="currentColor" d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path>
                                        </svg>
                                    </a>}
                                    {dataModal?.socials?.youtube && <a href={dataModal.socials.youtube} target="_blank" className="h-6 w-6 flex items-center justify-center bg-black rounded-full">
                                        <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fillRule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clipRule="evenodd" />
                                        </svg>
                                    </a>}

                                </div>
                                <p className="text-black text-xs flex items-end gap-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-map-pin text-[#9B8665]"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18.364 4.636a9 9 0 0 1 .203 12.519l-.203 .21l-4.243 4.242a3 3 0 0 1 -4.097 .135l-.144 -.135l-4.244 -4.243a9 9 0 0 1 12.728 -12.728zm-6.364 3.364a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z" /></svg> <span><span className="text-[#9B8665] font-bold">{dataModal.replace}</span>{dataModal.name.replace(dataModal.replace, '')}</span>
                                </p>
                                <a href={ dataModal?.mapLink ? dataModal.mapLink : `https://www.google.com/maps/search/?api=1&query=${dataModal.address}`} target="_blank" className="text-black text-justify text-[.7em] underline flex gap-1 items-end">
                                    {dataModal.address}
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center justify-around">
                            <button onClick={() => changeTab('gallery')}>
                                <img src={`/images/icons/photo-icon${currentTab == 'gallery' ? '1' : '2'}.png`} alt="photo-button" className="w-10" />
                            </button>
                            <button onClick={() => changeTab('video')}>
                                <img src={`/images/icons/video-icon${currentTab == 'video' ? '1' : '2'}.png`} alt="video-button" className="w-10" />
                            </button>
                            <button className="bg-[#9B8665] text-white rounded-lg px-5 py-2" onClick={() => toggleModal(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}