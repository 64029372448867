import { create } from "zustand";

export const modalStore = create((set) => {
    return {
        isVisible: false,
        dataModal: {},
        dataId: null,
        currentTab: null,
        toggleModal: (isVisible=false, dataModal = {}, currentTab=null) => set(state => ({
            isVisible,
            dataModal,
            currentTab
        })),
        changeTab: (currentTab=null) => set(state => ({
            currentTab
        }))
    }
})