const Footer = () => {
    return <>
        <div className="fotter bg-black h-auto md:h-[10em] py-4 md:py-0 text-white grid md:grid-flow-row md:grid-cols-3 grid-cols-none items-center gap-4">
            <div className="flex justify-center">
                <a href="/">
                    <img src="https://i.imghippo.com/files/tu4443NnI.png" alt="logo" className='w-24' />
                </a>
            </div>

            <div className="flex flex-col mx-auto justify-center boder-0 border-[#9b8665] md:border-x-2 px-0 md:px-10 h-[40%]">
                <p className='text-[#9B8665] font-semibold md:text-left text-center mb-2'>Contactános</p>
                <p>
                    <a href="mailto:joaquin@wolftowers.com">
                        <svg className="w-6 h-6 text-[#9B8665] mr-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                        </svg>
                        joaquin@wolftowers.com
                    </a>
                </p>
                <p className='flex'>
                    <svg className="w-6 h-6 text-[#9B8665] mr-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clipRule="evenodd" />
                    </svg>
                    <span>Plaza Wolf. Avenida Colegios,<span className='block'>L13 SM 307 MZ 49</span></span>
                </p>
            </div>

            <div className="flex flex-col gap-2 justify-center mx-auto px-5 w-[80%] md:w-[20%] lg:w-[40%] col-span-full md:col-auto">
                <p className='text-[#9B8665] font-semibold md:text-left text-center'>Síguenos en</p>
                <div className='flex justify-between gap-2'>
                    <span className='bg-white rounded-full'>
                        <a href="https://www.facebook.com/OriginByWolf/" target="_blank" rel="noreferrer">
                            <svg className="w-5 h-5 text-black pl-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </span>
                    <span className='bg-white w-5 h-5 flex items-center justify-center rounded-full'>
                        <a href="https://www.instagram.com/originbywolf/" target="_blank" rel="noreferrer">
                            <svg className="w-4 h-4 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path fill="currentColor" fillRule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </span>
                    <span className='bg-white w-5 h-5 flex items-center justify-center rounded-full'>
                        <a href="https://www.tiktok.com/@originbywolf" target="_blank" rel="noreferrer">
                            <svg className='w-4 h-4 text-black' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path>
                            </svg>
                        </a>
                    </span>
                    <span className='bg-white w-5 h-5 flex items-center justify-center rounded-full'>
                        <a href="https://www.youtube.com/@originbywolf1988" target="_blank" rel="noreferrer">
                            <svg className="w-4 h-4 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </>
}

export default Footer;