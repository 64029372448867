export default ({goto}) => {
    return <>
        <div className="grid grid-flow-row relative h-screen after:absolute after:top-0 after:w-full after:h-full after:from-30% after:bg-gradient-to-t after:from-[rgba(0,0,0,.96)] after:to-transparent bg-[url(https://i.imghippo.com/files/SMP8197DDM.jpg)] bg-center bg-cover bg-no-repeat">
            <div className="z-10 flex flex-col justify-around p-6">
                <div>
                    <h1 className="text-white text-xl">STEP <span className="text-[#A78E64]">BEYOND</span> <span className="font-bold">LIVING</span></h1>
                </div>
                <div className="h-20"></div>
                <div>
                    <img src="https://i.imghippo.com/files/tu4443NnI.png" alt="logo" className="h-24" />
                </div>
                <a href="intent://com.android.settings/#Intent;scheme=android-app;end" className="text-white">Open applications</a>
                <div className="flex flex-col justify-between">
                    <p className="text-white text-4xl font-light"><span className="font-bold">Encuentra</span> y obtén</p>
                    <div className="relative -left-6 h-1 bg-[#9B8665] w-[55%] mt-1.5"></div>
                    <p className="text-white text-4xl font-light">tu mejor comida</p>
                </div>
                <div className="flex justify-center">
                    <div className="p-2" style={{backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%239B8665FF' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",borderRadius: '100px'}}>
                        <button onClick={goto} className="bg-[#9B8665] text-white flex items-center justify-center p-2 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}