import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import Map from './components/Map';
import './App.css';
import Modal from './components/Modal';
import { modalStore } from './store/modalsStore';
import { isMobile } from 'react-device-detect';
import MobileHome from './components/MobileHome';
import { useRef, useState } from 'react';
import Menu from './components/ui/Menu';

function App() {
  const [showMobileHome, setShowMobileHome] = useState(isMobile);
  const { isVisible } = modalStore();
  const mapRef = useRef();
  const goto = () => {
    setShowMobileHome(false);
  }
  const mapMoveTo = (long, lat) => {
    mapRef.current.flyTo({
      center: [long, lat],
      zoom: 18,
      offset: [0, -100],
    });
  }
  return (
    <div className="App">
      {
        showMobileHome ? <MobileHome goto={goto} /> :
          <div className='w-full flex-wrap bg-black flex flex-col h-auto mx-auto overflow-x-hidden'>
            <Header />
            <div className="body relative h-[36em] md:h-[46em] border-t-0 md:border-t-4 md:border-t-[#9B8665] after:block after:md:hidden after:absolute after:h-1.5 after:bg-[#9B8665] after:w-[60%] after:mx-auto after:top-0 after:left-0 after:right-0">
              <div className='w-12 hidden md:block absolute -top-9 bg-[#D9D9D9] rounded-full z-[50] mx-auto left-0 border-[#9B8665] border-2 p-2 right-0'>
                <img src="http://zonahuayacan.com.mx/images/frame.png" alt="utils" className='w-full h-full' />
              </div>

              <div className='flex h-full relative'>
                <div className='w-2/4 lg:w-2/4 xl:w-1/4 px-6 bg-[#9B8665] h-full hidden md:block relative'>
                  <div className="h-[55%] flex flex-col">
                    <div className='flex flex-1 flex-col justify-end relative'>
                      <span className='w-1.5 h-[102%] bg-black absolute -top-4 left-0' />
                      <p className='ml-[10%] mb-8'>¿Que te gustaría <span className='block font-extrabold'>comer hoy?</span></p>
                      <p className='ml-[10%] text-sm text-justify'>Conoce la zona más trendy para vivir en Cancún, donde podrás encontrar exclusivos espacios, restaurantes, plazas comerciales y desarrollos de lujo como Origin by Wolf, donde podrás comenzar tu vida en el Caribe.</p>
                    </div>
                  </div>
                </div>
                <div className='w-full bg-white h-full overflow-hidden relative'>
                  <Menu mapMoveTo={mapMoveTo} />
                  <Map mapRef={mapRef} mapMoveTo={mapMoveTo} />
                </div>
              </div>
            </div>
            <Footer />
          </div>
      }
      {isVisible && <Modal />}
    </div>
  );
}

export default App;
