import { MutableRefObject, useEffect, useState } from "react";

export default function useElementIsOffset(parentref) {
    const [reached, setReached] = useState(false);
    useEffect(() => {

        const handleScroll = () => {
            // @ts-ignore
            if(parentref.current.scrollLeft >= 90) {
                setReached(true);
            } else {
                setReached(false);
            }
        };
        //@ts-ignore
        parentref.current?.addEventListener("scroll", handleScroll)

        return () => {
            //@ts-ignore
            parentref.current?.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return reached;
}