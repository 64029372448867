import { menuStore } from "../../store/menuStore";

const Header = () => {
    const {isMenuVisible} = menuStore();
    const {toggleMenu} = menuStore();
    return <>
        <div className="header h-[10em] md:h-[16em] relative z-[3] flex flex-col items-center justify-center">
            <div className='logo border-b-0 md:border-b-[1px] md:border-b-[#9b8665] px-8 py-4'>
                <img src="https://i.imghippo.com/files/tu4443NnI.png" alt="logo" className='w-28 mx-auto' />
            </div>
            <div className='hidden md:block'>
                <p className='text-white py-4'><span className='font-extrabold'>Encuentra</span> y obtén tu mejor comida</p>
            </div>
            <div className='absolute right-[3em]'>
                {
                    isMenuVisible ?
                    <button onClick={()=>toggleMenu()} className="duration-100 ease-out transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-white"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                    </button> : 
                    <button onClick={()=>toggleMenu(true)} className="duration-100 ease-out transition-all">
                        <svg className='text-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                            <path d="M4 6l16 0"></path>
                            <path d="M4 12l16 0"></path>
                            <path d="M4 18l16 0"></path>
                        </svg>
                    </button>
                }
            </div>
        </div>
    </>
}

export default Header;