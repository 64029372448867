import { create } from "zustand";

export const menuStore = create((set) => {
    return {
        isMenuVisible: false,
        activeIndex: 1,
        places: {},
        toggleMenu: (val=false) => set(state => ({
            isMenuVisible: val,
        })),
        setActiveIndex: (index={}) => set(state => ({
            activeIndex: index
        })),
        setPlaces: (values={}) => set(state => ({
            places: values
        }))
    }
})